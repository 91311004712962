a {
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: underline;
}

.navbar-comp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100vw;
    height: 2.5em;
    border: 1px solid black;
    padding-top: 1em;
}

.menu-item {
    margin-right: 1em;
}

.icon {
    width: 70%;
    height: auto;
} 

.avatar {
    width: 0.5em;
    height: auto;
}

.search-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#logo {
    padding-top: 12em;
}

#text-search {
    display: flex;
    padding-top: 1em;
    width: 40em;
    height: 2.5em;
    border-radius: 5em / 5em;
    border: 1px solid #f8f9fa;
    flex-flow: row flex-start;
    justify-content: space-around;
    padding-top: 0.7em;
}

#text-search:hover {
    box-shadow: 0em 0.15em 0.5em 0.18em #e0e0e0;
}

.searchbox {
    height: 1.6em;
    width: 33em;
    border: none;
    font-size: 16px;
}

.searchbox:focus {
    outline: none;
}

.magnifying {
    width: 1em;
    margin-top: -0.5em;
}

.voice-icon {
    width: 1.5em;
    margin-top: -0.5em;
}

.search-panal {
    display: flex;
    justify-content: center;
    padding-top: 3em;
}

.search-panal-text {
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.search-btn {
    font-size: 0.8em;
    padding: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: none;
    border-radius: 0.5em;
    background-color: #f8f9fa;
    color: #3c4043;
    font-weight: 500;
    margin-right: 1em;
}

.search-btn:hover {
    outline: 1px solid #e0e0e0;
    box-shadow: 0em 0.15em 0.1em 0.05em #e0e0e0;
}

.subtext {
    font-size: 0.85em;
}

.lang-link {
    color: blue;
    padding: 0.5em;
}